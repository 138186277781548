// File: DynamoDBService.js
import { DynamoDBClient, ScanCommand } from "@aws-sdk/client-dynamodb";
import { unmarshall } from "@aws-sdk/util-dynamodb";

const fetchDataFromDynamoDB = async () => {
    const client = new DynamoDBClient({
        region: 'eu-central-1', // Replace with your AWS region
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
        },
    });

    const params = {
        TableName: 'school_integrity_parent_table', // Replace with your actual table name
    };

    try {
        const { Items } = await client.send(new ScanCommand(params));
        return (Items ?? []).map(item => unmarshall(item));
    } catch (error) {
        console.error('Error scanning DynamoDB:', error);
        throw error; // Re-throw the error for the calling code to handle if needed
    }
};

export default fetchDataFromDynamoDB;
