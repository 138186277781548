import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './LogisticsHome.css';
import { Link, useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { PersonLinesFill, Speedometer2, SignpostSplit, CameraVideo, PersonUp, PersonDown } from 'react-bootstrap-icons';

import { Amplify } from 'aws-amplify';
import awsExports from '../../aws-exports';
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsExports);

const theme = {
  name: 'custom-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '#232F3E',
          80: '#FF9900',
          90: '#FFA726',
          100: '#FF9900',
        },
      },
      background: {
        primary: '#F7F7F7',
        secondary: '#FFFFFF',
      },
      font: {
        interactive: '#232F3E',
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: '#232F3E',
          _hover: {
            backgroundColor: '#1D2731',
          },
        },
      },
      heading: {
        color: '#232F3E',
      },
    },
  },
};

function LogisticsPlatformHome({ signOut, user }) {
    const navigate = useNavigate();

    const offerings = [
      
        { 
          name: "Admin Portal", 
          icon: <PersonLinesFill size={48} />, 
          description: "Access comprehensive profiles of student, for onboarding, registration, teacher/parent communication, and route allocation.",
          path: "/admin"
        },
        { 
            name: "Vehicle Live Video", 
            icon: <CameraVideo size={48} />, 
            description: "Stream real-time video from vehicles to monitor activities, enhance security, and ensure compliance with safety protocols.",
            // path: "/vehicle-live-video"
        },
        { 
            name: "Vehicle Route", 
            icon: <SignpostSplit size={48} />, 
            description: "Provides insights into vehicle journeys, including stop times and locations, essential for structured scheduling and punctuality.",
            // path: "/vehicle-route"
        },      
        { 
            name: "Vehicle Odometer", 
            icon: <Speedometer2 size={48} />, 
            description: "The Odometer tool provides real-time data on distance traveled.",
            path: "/vehicle-odometer"
        },
        { 
          name: "Vehicle Boarding", 
          icon: <PersonUp size={48} />, 
          description: "Ensure students to and from school, board the right vehicle, and are checked in safely.",
          // path: "/communications"
        },        
        { 
            name: "Vehicle Alighting", 
            icon: <PersonDown size={48} />, 
            description: "Ensure students are checked out of vehicles safely and can be accounted for.",
            // path: "/pickup-dropoff-optimization"
        },

    ];

    return (
        <div className="container-fluid mt-4">
            <div className="header-section">
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(10px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                    // startIcon={<i className="bi bi-arrow-left-circle"></i>}
                >
                    Back
                </Button>
                <div>
                    {/* <span style={{ marginRight: '10px', color: '#232F3E' }}>Welcome, {user.username}</span> */}
                    <Button 
                        variant="contained"
                        onClick={signOut}
                        sx={{ 
                            backgroundColor: '#232F3E', 
                            color: '#FFFFFF', 
                            '&:hover': { backgroundColor: '#232F3E' },
                            fontSize: 'clamp(10px, 1.5vw, 14px)',
                            borderRadius: '10px',
                            padding: '8px 16px',
                            textTransform: 'none',
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
            </div>
            <div className="row">
                {offerings.map((offering, index) => (
                    <div className="col-6 col-sm-4 col-md-3 p-2 d-flex align-items-stretch" key={index}>
                        <Link to={offering.path} className="text-decoration-none w-100">
                            <div className="card mb-4 flex-grow-1 text-left h-100">
                                <div className="card-body d-flex flex-column justify-content-center">
                                    {offering.icon}
                                    <h5 className="card-title mt-3">{offering.name}</h5>
                                    <p className="card-text">{offering.description}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default withAuthenticator(LogisticsPlatformHome, {
  theme,
});

// export default LogisticsPlatformHome