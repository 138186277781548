import React from "react";
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import './Industrial.css';
import cellsoft from '../../assets/cellsoft.png';
import totalenergies from '../../assets/totalenergy.png';
// import inverterpower from '../../assets/inverterpower.png';
import sunday from '../../assets/sunday.png';
import furahabuild from '../../assets/furaha.png';

function Industrial() {
    const navigate = useNavigate();

    return (
        <div className="container mt-4">
            <Button 
                variant="contained"
                onClick={() => navigate(-1)} 
                className="backButton"
            >
                Back
            </Button>

            <h1 className="text-center industrial-heading">
                Industrial Insights
            </h1>

            <div className="industrial-section">
                <p className="industrial-text">
                    <strong>Real-time analytics</strong> are reshaping the landscape of modern business by enabling unprecedented 
                    levels of efficiency and insight. It is crucial for businesses and stakeholders alike to understand the power of these 
                    technologies. They provide the tools to anticipate trends, optimize operations, and adapt to changes.
                    Mastering these 
                    technologies ensures leadership in a rapidly evolving, data-driven world. 
                    </p>
                </div>

            {/* Partner Section */}
            <div className="partner-section">
            <h4 className="section-heading">Completed Projects</h4>
                <div className="partner-list">
                    {/* Total Energies & Inverter Power Systems */}
                    <div className="partner-item">
                        <img src={totalenergies} alt="Total Energies Logo" className="partner-logo" />
                        <div>
                            <h5>Total Energies & Inverter Power Systems, Nairobi Kenya</h5>
                            <p>Power Monitoring Solutions (June 2024 - August 2024)</p>
                            <p>
                                Our company partnered with Total Energies and Inverter Power Systems to develop a comprehensive power and UPS monitoring tool, 
                                significantly improving system reliability. This tool achieved real-time tracking and automation, which led to a 30% increase in 
                                system uptime and reduced manual intervention.
                            
                            <ul>
                                <li>Developed power and UPS monitoring solutions for real-time tracking.</li>
                                <li>Integrated with existing energy systems, enhancing system reliability.</li>
                                <li>Automated reporting, reducing manual tasks and boosting operational efficiency.</li>
                                <li>Validated systems for accuracy, resulting in a 30% increase in uptime.</li>
                            </ul>
                            </p>
                        </div>
                        {/* <img src={inverterpower} alt="Inverter Power Systems Logo" className="partner-logo" /> */}
                    </div>

                    {/* FurahaBuild Ventures Ltd */}
                    <div className="partner-item">
                        <img src={furahabuild} alt="FurahaBuild Ventures Logo" className="partner-logo" />
                        <div>
                            <h5>FurahaBuild Ventures Ltd, Nairobi Kenya</h5>
                            <p>Internet of Things Solutions (April 2023 - July 2024)</p>
                            <p>
                                We collaborated with FurahaBuild Ventures Ltd to lead customer discovery, design, and deploy Internet-of-Things solutions tailored 
                                to specific needs. This project involved intensive prototyping, feedback-based refinements, and seamless integration of IoT systems.
                            
                            <ul>
                                <li>Led user discovery and identified key pain points for IoT systems.</li>
                                <li>Designed and deployed IoT solutions tailored to customer needs.</li>
                                <li>Managed solution deployment and provided ongoing support.</li>
                                <li>Conducted user testing and refined prototypes based on feedback.</li>
                            </ul>
                            </p>
                        </div>
                    </div>

                    {/* Cellsoft Technologies */}
                    <div className="partner-item">
                        <img src={cellsoft} alt="Cellsoft Technologies Logo" className="partner-logo" />
                        <div>
                            <h5>Cellsoft Technologies, Nairobi Kenya</h5>
                            <p>IoT Infrastructure Consulting (October 2023 - January 2024)</p>
                            <p>
                                Our team collaborated with Cellsoft Technologies to develop a scalable backend infrastructure for a LoRaWAN-based IoT platform. 
                                The infrastructure enabled efficient device management, enhanced security, and seamless data analysis for improved performance.
                            </p>
                        </div>
                    </div>

                    {/* Sunday Robotics */}
                    <div className="partner-item">
                        <img src={sunday} alt="Sunday Robotics Logo" className="partner-logo" />
                        <div>
                            <h5>Sunday Robotics, Antalya Turkiye</h5>
                            <p>Machine Learning Collaboration (September 2022 - March 2023)</p>
                            <p>
                                Partnered with Sunday Robotics to develop an embedded offline speech recognition system. This system, designed for electrical control 
                                panels, leveraged machine learning techniques to optimize performance, even in offline environments, ensuring robust and accurate operations.
                            
                            <ul>
                                <li>Developed an offline speech recognition system using machine learning.</li>
                                <li>Optimized performance for control panels in electrical distribution systems.</li>
                                <li>Collaborated with research teams to refine system functionality.</li>
                                <li>Presented the final product at an international tech exposition.</li>
                            </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Industrial;