import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter } from 'react-table';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, InputGroup, Card, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BsPencilSquare, BsTrash, BsSearch, BsPeople, BsGeoAlt, BsPinMap, BsCheck, BsX } from 'react-icons/bs';

import './ViewEditStudentRecords.css';

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
  students,
}) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [showEditOption, setShowEditOption] = useState(false);
  const [error, setError] = useState(null);

  const onChange = (e) => {
    setValue(e.target.value);
    setError(null);
  };

  const onBlur = () => {
    if (id === 'AdmissionNumber') {
      const isDuplicate = students.some((student, idx) => 
        student.AdmissionNumber === value && idx !== index
      );
      if (isDuplicate) {
        setError('This Admission Number already exists');
        return;
      }
    }
    updateMyData(index, id, value);
    setIsEditing(false);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const renderEditContent = () => {
    if (id === 'Trip') {
      return (
        <Form.Select value={value} onChange={onChange} onBlur={onBlur} autoFocus>
          <option value="Morning">Morning</option>
          <option value="Afternoon">Afternoon</option>
          <option value="Both">Both</option>
        </Form.Select>
      );
    } else {
      return (
        <Form.Control
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus
          isInvalid={!!error}
        />
      );
    }
  };

  return (
    <div
      onMouseEnter={() => setShowEditOption(true)}
      onMouseLeave={() => setShowEditOption(false)}
      style={{ position: 'relative' }}
    >
      {isEditing ? (
        <InputGroup>
          {renderEditContent()}
          <Button variant="outline-success" onClick={onBlur}>
            <BsCheck />
          </Button>
          <Button variant="outline-danger" onClick={() => {
            setIsEditing(false);
            setError(null);
          }}>
            <BsX />
          </Button>
        </InputGroup>
      ) : (
        <>
          <span>{value}</span>
          {showEditOption && !isEditing && (
            <Button 
              variant="link" 
              size="sm" 
              onClick={() => setIsEditing(true)}
              style={{ position: 'absolute', top: 0, right: 0, color: 'black' }}
            >
              <BsPencilSquare />
            </Button>
          )}
        </>
      )}
      {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </div>
  );
};

const ViewEditStudentRecords = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalStudents, setTotalStudents] = useState(0);
  const [uniqueZones, setUniqueZones] = useState(0);
  const [uniqueLocations, setUniqueLocations] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
        const response = await axios.get(`${apiEndpoint}/view-and-edit-student-data`);
        const studentData = response.data;
        setStudents(studentData);
        setLoading(false);
        calculateCounts(studentData);
      } catch (error) {
        setError('Failed to fetch students. Please try again later.');
        setLoading(false);
      }
    };

    fetchStudents();
  }, [refreshKey]);

  
  const calculateCounts = (studentData) => {
    const totalStudentCount = studentData.length;
    const zonesSet = new Set(studentData.map(student => student.Zone));
    const locationsSet = new Set(studentData.map(student => student.Location));

    setTotalStudents(totalStudentCount);
    setUniqueZones(zonesSet.size);
    setUniqueLocations(locationsSet.size);
  };

  const handleDelete = async (admissionNumber, Grade, Name) => {
    try {
      const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
      const requestBody = { 
        AdmissionNumber: admissionNumber,
        Grade: Grade
      };
      await axios.delete(`${apiEndpoint}/view-and-edit-student-data`, { data: requestBody });
      setRefreshKey(prevKey => prevKey + 1);
      setAlertMessage({ type: 'success', text: `Successfully deleted ${Name} (${admissionNumber})` });
    } catch (error) {
      console.error('Error deleting student:', error);
      setAlertMessage({ type: 'danger', text: 'Failed to delete student. Please try again.' });
    }
  };

  const updateMyData = async (rowIndex, columnId, value) => {
    try {
      if (columnId === 'AdmissionNumber') {
        const isDuplicate = students.some((student, idx) => 
          student.AdmissionNumber === value && idx !== rowIndex
        );
        if (isDuplicate) {
          setAlertMessage({ type: 'warning', text: 'This Admission Number already exists. Please use a unique number.' });
          return;
        }
      }

      const updatedData = {
        ...students[rowIndex],
        [columnId]: value
      };
      
      const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
      await axios.put(`${apiEndpoint}/view-and-edit-student-data`, updatedData);
      
      setStudents(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return updatedData;
          }
          return row;
        })
      );
      setAlertMessage({ type: 'success', text: 'Student data updated successfully.' });
    } catch (error) {
      // console.error('Error updating student:', error);
      setAlertMessage({ type: 'danger', text: 'Failed to update student. Please try again.' }); 
    }
    setRefreshKey(prevKey => prevKey + 1);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Admission Number',
        accessor: 'AdmissionNumber',
        // Cell: (props) => <EditableCell {...props} students={students} />,
      },
      {
        Header: 'Name',
        accessor: 'Name',
        Cell: EditableCell,
      },
      {
        Header: 'Grade',
        accessor: 'Grade',
        // Cell: EditableCell,
      },
      {
        Header: 'Location',
        accessor: 'Location',
        Cell: EditableCell,
      },
      {
        Header: 'Trip',
        accessor: 'Trip',
        Cell: EditableCell,
      },
      {
        Header: 'Zone',
        accessor: 'Zone',
        Cell: EditableCell,
      },
      {
        Header: 'Delete',
        Cell: ({ row }) => (
          <div className="action-buttons">
            <Button variant="danger" size="sm" onClick={() => handleDelete(
              row.original.AdmissionNumber, 
              row.original.Grade,
              row.original.Location,
              row.original.Zone,
              row.original.Trip,
              row.original.Name,
            )}>
              <BsTrash />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    { 
      columns, 
      data: students,
      updateMyData,
    },
    useGlobalFilter
  );

  const { globalFilter } = state;

  if (loading) return <div className="student-records-container">Loading...</div>;
  if (error) return <div className="student-records-container">{error}</div>;

  return (
    <div className="student-records-container">
      <div className="header-section">
        <Button
          variant="primary"
          onClick={() => navigate(-1)}
          className="back-button"
        >
          Back
        </Button>
      </div>
      <h1 className="mb-4">Student Records</h1>

      {alertMessage && (
        <Alert 
          variant={alertMessage.type} 
          onClose={() => setAlertMessage(null)} 
          dismissible
        >
          {alertMessage.text}
        </Alert>
      )}

      <InputGroup className="mb-3">
        <InputGroup.Text id="search-addon">
          <BsSearch />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Search by admission number or name"
          value={globalFilter || ''}
          onChange={e => setGlobalFilter(e.target.value)}
        />
      </InputGroup>

      <table {...getTableProps()} className="student-records-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();  // Destructure key
            return (
              <tr key={key} {...rowProps}>  {/* Pass key separately */}
                {row.cells.map(cell => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();  // Handle cell key similarly
                  return (
                    <td key={cellKey} {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Row className="counts-section mb-2">
        <Col md={4}>
          <Card className="text-center mini-card">
            <Card.Body>
              <BsPeople className="count-icon text-primary" />
              <Card.Title className="mini-title">Total Students</Card.Title>
              <Card.Text className="mini-count">{totalStudents}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="text-center mini-card">
            <Card.Body>
              <BsGeoAlt className="count-icon text-success" />
              <Card.Title className="mini-title">Zones</Card.Title>
              <Card.Text className="mini-count">{uniqueZones}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="text-center mini-card">
            <Card.Body>
              <BsPinMap className="count-icon text-info" />
              <Card.Title className="mini-title">Locations</Card.Title>
              <Card.Text className="mini-count">{uniqueLocations}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewEditStudentRecords;