import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminHome.css';
import { Link, useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { PersonPlus, FileText, PencilSquare, PersonCheck, PersonRolodex, PersonVcard } from 'react-bootstrap-icons';

const offerings = [
    { 
        name: "Onboard Student", 
        icon: <PersonPlus size={48} />, 
        description: "Register new students for the transport system, assigning routes and schedules.",
        path: "/onboard-student"
    },
    { 
        name: "View or Edit Student Records", 
        icon: <PencilSquare size={48} />, 
        description: "View detailed student records including attendance and route history.",
        path: "/view-student-records"
    },
    { 
        name: "See Updates", 
        icon: <FileText size={48} />, 
        description: "View the day's updates on student drop-off/pick-up times and location.",
        // path: "/make-updates"
    },
    { 
        name: "Communicate with Teacher(s)", 
        icon: <PersonRolodex size={48} />, 
        description: "Coordinate with teachers about students' transport schedules.",
        // path: "/communicate-teachers"
    },
    { 
        name: "Communicate with Driver(s)", 
        icon: <PersonVcard size={48} />, 
        description: "Send messages and updates to the drivers.",
        // path: "/communicate-drivers"
    },
    { 
        name: "Communicate with Parent(s)", 
        icon: <PersonCheck size={48} />, 
        description: "Inform parents about route changes, delays, and updates.",
        // path: "/communicate-parents"
    },
    
];

function AdminHome() {
    const navigate = useNavigate();

    return (
        <div className="admin-container-fluid mt-4">
            <div className="admin-header-section">
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(10px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                >
                    Back
                </Button>
            </div>
            <div className="row">
                {offerings.map((offering, index) => (
                    <div className="col-6 col-sm-4 col-md-3 p-2 d-flex align-items-stretch" key={index}>
                        <Link to={offering.path} className="admin-text-decoration-none w-100">
                            <div className="admin-card mb-4 flex-grow-1 text-left h-100">
                                <div className="admin-card-body d-flex flex-column justify-content-center">
                                    {offering.icon}
                                    <h5 className="admin-card-title mt-3">{offering.name}</h5>
                                    <p className="admin-card-text">{offering.description}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminHome;
