import React, { useState, useRef } from 'react';
import { Container, Form, Button, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { PersonBadge, Person, Star, Geo, Activity } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './StudentRegistrationForm.css';

function StudentRegistrationForm() {
  const navigate = useNavigate();
  const fileInputRef = useRef();

  const [formData, setFormData] = useState({
    AdmissionNumber: '',
    name: '',
    image: null,
    imageUrl: '',
    grade: '',
    zone: '',
    location: '',
    tripFrequency: '',
  });
  // eslint-disable-next-line 
  const [uploadURL, setUploadURL] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.type !== 'image/jpeg') {
      setAlert({ type: 'danger', message: 'Wrong file type - JPG only.' });
      return;
    }
    if (file.size > 1000000) {
      setAlert({ type: 'danger', message: 'Image is too large.' });
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
        imageUrl: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_ENDPOINT);
      const uploadUrl = response.data.uploadURL;
      const Key = response.data.Key;

      const binary = atob(formData.imageUrl.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

      await fetch(uploadUrl, {
        method: 'PUT',
        body: blobData,
      });

      const imageUrl = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${Key}`;
      setUploadURL(imageUrl);
      console.log('Image uploaded successfully!');
      return imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setAlert(null);
  
    if (!formData.image) {
      setAlert({ type: 'danger', message: 'Please upload an image before submitting.' });
      setIsSubmitting(false);
      return;
    }
  
    try {
      const imageUrl = await handleImageUpload();
  
      const studentData = {
        AdmissionNumber: formData.AdmissionNumber,
        name: formData.name,
        imageUrl: imageUrl,
        grade: formData.grade,
        zone: formData.zone,
        location: formData.location,
        tripFrequency: formData.tripFrequency,
      };
  
      const response = await axios.post(process.env.REACT_APP_API_DB_ENDPOINT, studentData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        // Reset form data after successful registration
        setFormData({
          AdmissionNumber: '',
          name: '',
          image: null,
          imageUrl: '',
          grade: '',
          zone: '',
          location: '',
          tripFrequency: '',
        });
        setUploadURL('');
  
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.value = '';
        }
  
        setAlert({ type: 'success', message: 'Student registered successfully!' });
      }
    } catch (error) {
      // Check if the error is related to Admission Number
      if (error.response && error.response.status === 400) {
        // Admission number already exists
        setAlert({ type: 'danger', message: 'Admission Number already exists. Please use a different one.' });
      } else {
        // Other errors
        setAlert({ type: 'danger', message: '(500) An error occurred. Please try again.' });
      }
      console.error('Error in form submission:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <Container className="student-registration-form">
      <div className='registration-container'>
        <div className="header-section">
          <Button
            variant="primary"
            onClick={() => navigate(-1)}
            className="back-button"
          >
            Back
          </Button>
        </div>
        <h2 className="registration-heading">Student Onboarding</h2>
        {alert && (
          <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
            {alert.message}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><PersonBadge /> Admission Number</Form.Label>
                <Form.Control
                  type="text"
                  name="AdmissionNumber"
                  value={formData.AdmissionNumber}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><Person /> Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Upload Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/jpeg"
              onChange={handleImageChange}
              ref={fileInputRef}
            />
            {formData.imageUrl && <img src={formData.imageUrl} alt="Preview" style={{ width: '30%', marginTop: '10px' }} />}
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><Star /> Grade</Form.Label>
                <Form.Select
                  name="grade"
                  value={formData.grade}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Grade</option>
                  {['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th'].map((grade) => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><Geo /> Zone</Form.Label>
                <Form.Control
                  type="text"
                  name="zone"
                  value={formData.zone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label><FontAwesomeIcon icon={faMapMarkerAlt} /> Location</Form.Label>
            <Form.Control
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><Activity /> Trip Frequency</Form.Label>
            <Form.Select
              name="tripFrequency"
              value={formData.tripFrequency}
              onChange={handleChange}
              required
            >
              <option value="">Select Frequency</option>
              {['Morning', 'Afternoon', 'Both'].map((frequency) => (
                <option key={frequency} value={frequency}>{frequency}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Button 
            type="submit" 
            variant="primary" 
            className="registration-button" 
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {' '}Registering...
              </>
            ) : 'Register Student'}
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default StudentRegistrationForm;