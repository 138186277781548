// File: VehicleOdometerDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import fetchDataFromDynamoDB from './DynamoDBService';
import { Button, Card, Typography, Grid, CircularProgress } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';

function VehicleOdometerDetail() {
    const [vehicleList, setVehicleList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lastUpdatedTime, setLastUpdatedTime] = useState(''); // State for storing last updated time
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                const vehicles = await fetchDataFromDynamoDB();
                setVehicleList(vehicles);
                const currentTime = new Date().toLocaleTimeString(); // Capture current time
                setLastUpdatedTime(currentTime); // Set last updated time
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchVehicles();
    }, []);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <CircularProgress sx={{ color: '#FF9900' }}/>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mt-4">
                <Typography variant="body1" color="error">
                    Error loading vehicles: {error}
                </Typography>
            </div>
        );
    }

    return (
        <div className="container mt-4">
            <Button 
                variant="contained"
                onClick={() => navigate(-1)} 
                sx={{ 
                    mb: 3, 
                    backgroundColor: '#232F3E', 
                    color: '#FFFFFF', 
                    '&:hover': { backgroundColor: '#1D2731' },
                    fontSize: 'clamp(10px, 1.5vw, 14px)',
                    borderRadius: '10px',
                    padding: '8px 16px',
                    textTransform: 'none',
                }}
                startIcon={<i className="bi bi-arrow-left-circle"></i>}
            >
                Back
            </Button>
            <Typography 
                variant="h5" 
                sx={{ 
                    color: '#FF9900', 
                    mb: 3, 
                    fontSize: 'clamp(18px, 3vw, 24px)', 
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}
            >
                Registered Vehicles
            </Typography>
            <Grid container spacing={4}>
                {vehicleList.length > 0 ? (
                    vehicleList.map((vehicle, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Link to={`/vehicle-odometer/${vehicle.deviceId}`} style={{ textDecoration: 'none' }}>
                                <Card sx={{ 
                                    height: '100%', 
                                    boxShadow: 3, 
                                    borderRadius: 2, 
                                    p: 2,
                                    backgroundColor: '#F7F7F7',
                                    '&:hover': {
                                        boxShadow: 6
                                    }
                                }}>
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            color: '#232F3E', 
                                            fontWeight: 'bold',
                                            mb: 2,
                                            textAlign: 'center',
                                            fontSize: 'clamp(16px, 2vw, 20px)',
                                        }}
                                    >
                                        {vehicle.vehicleRegNumber}
                                    </Typography>
                                    <Typography 
                                        sx={{ 
                                            color: '#333333', 
                                            textAlign: 'center',
                                            fontSize: 'clamp(12px, 1.5vw, 14px)',
                                        }}
                                    >
                                        Total Distance Covered: {vehicle.totalDistanceCovered} km
                                    </Typography>
                                    <Typography 
                                        sx={{ 
                                            color: '#666666', 
                                            textAlign: 'center',
                                            fontSize: 'clamp(12px, 1.5vw, 14px)',
                                        }}
                                    >
                                        Last Updated: {lastUpdatedTime} {/* Display last updated time */}
                                    </Typography>
                                </Card>
                            </Link>
                        </Grid>
                    ))
                ) : (
                    <Typography sx={{ color: '#333333', textAlign: 'center', width: '100%' }}>
                        No vehicles found.
                    </Typography>
                )}
            </Grid>
        </div>
    );
}

export default VehicleOdometerDetail;
