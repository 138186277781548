import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import LogisticsPlatformHome from './pages/LogisticsHome/LogisticsHome';
import About from './pages/About/About';
import Careers from './pages/Careers/Careers';
import Guide from './pages/Guide/Guide';
import Contacts from './pages/Contacts/Contacts';

import Industrial from './pages/IndustrialPage/Industrial';

import VehicleOdometerDetail from './pages/Odometer/VehicleOdometerDetails';
import VehicleDetailPage from './pages/Odometer/VehicleDetailPage';


import AdminHome from './pages/AdminPage/AdminHome';
import StudentRegistrationForm from './components/StudentRegistrationForm';
import ViewEditStudentRecords from './components/ViewEditStudentRecords';

const App = () => {
  return (
    <Router>
      <div className="d-flex">
        <div className="content-area flex-grow-1">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/lph" element={<LogisticsPlatformHome />} />
            <Route path="/about" element={<About />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/user_guide" element={<Guide />} />
            <Route path="/contacts" element={<Contacts />} />

            <Route path="/vehicle-odometer" element={<VehicleOdometerDetail />} />
            <Route path="/vehicle-odometer/:deviceId" element={<VehicleDetailPage />} />

            <Route path="/admin" element={<AdminHome />} />
            <Route path="/onboard-student" element={<StudentRegistrationForm />} />
            <Route path="/view-student-records" element={<ViewEditStudentRecords />} />

            <Route path="/industrial" element={<Industrial />} />
            
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
