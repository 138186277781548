import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import styles from './About.module.css';
import logo from '../../assets/logo512.png';

function About() {
    const navigate = useNavigate();

    return (
        <div className={`container mt-4 ${styles.aboutContainer}`}>
           
            <Button 
                variant="contained"
                onClick={() => navigate(-1)} 
                className={styles.backButton}
                startIcon={<i className="bi bi-arrow-left-circle"></i>}
            >
                Back
            </Button>
             {/* Logo section */}
             <div className={styles.logoContainer}>
                <img src={logo} alt="Company Logo" className={styles.logo} />
            </div>
            <div className={styles.infoSection}>
                <h2 className={styles.sectionHeading}>
                   <strong> Data Intelligence & Machines</strong> 
                </h2> 
                <p><strong>Our name, DIM,</strong> not only reflects our mission but also defines who we are, guiding every aspect of our product development.
                   We fully capitalize on the power of <strong>data</strong> to create <strong>intelligent solutions</strong> that empower businesses and enhance decision-making. 
                   By focusing on <strong>innovation and excellence</strong>, we strive to transform industries and elevate how organizations utilize data.</p>
            </div>

            <div className={styles.infoSection}>
                <h2 className={styles.sectionHeading}>
                    <strong> Our People</strong>
                </h2>
                <p><strong>Our team</strong> embodies the best in both <strong>engineering</strong> and <strong>scientific temperament</strong> and talent. 
                   This synergy is to be envied, as it allows for a balanced approach to problem-solving—utilizing 
                   both practical engineering solutions and deep scientific understanding.</p>
            </div>
        </div>
    );
}

export default About;