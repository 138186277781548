import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Speedometer2, PersonLinesFill, Chat, SignpostSplit, CameraVideo, ClockHistory, InfoCircle, Box, ExclamationTriangle, CheckCircle } from 'react-bootstrap-icons';
import styles from './Guide.module.css';

function Guide() {
    const navigate = useNavigate();

    const products = [
        { icon: <CameraVideo />, name: "Vehicle Live Video", description: "Stream real-time video from vehicles to monitor activities and enhance security." },
        { icon: <Chat />, name: "Communications", description: "Facilitates seamless communication between parents, teachers, and drivers." },
        { icon: <ClockHistory />, name: "Pickup/Drop-off Optimization", description: "Optimizes pickup and drop-off schedules to minimize wait times and enhance route efficiency." },
        { icon: <Speedometer2 />, name: "Vehicle Odometer", description: "Provides real-time data on the distance traveled by each vehicle." },
        { icon: <PersonLinesFill />, name: "Driver Details", description: "Access comprehensive profiles of drivers, including their driving history and performance metrics." },
        { icon: <SignpostSplit />, name: "Vehicle Route", description: "Provides detailed insights into vehicle journeys, including stops and duration." },
    ];

    return (
        <div className={styles.container}>
            <Button 
                variant="contained"
                onClick={() => navigate(-1)} 
                className={styles.backButton}
                startIcon={<i className="bi bi-arrow-left-circle"></i>}
            >
                Back
            </Button>
            <h1 className={styles.title}>Logistics User Guide</h1>
            <p className={styles.intro}>
                Welcome to the user guide for the Logistics (school transportation/safety) Product. 
                This guide will walk you through the various products available in the system, helping you understand their functionalities and how to effectively use them.
            </p>

            <h2 className={styles.sectionTitle}>
                <Box className={styles.icon} /> Products Overview
            </h2>
            <p>The system provides several products designed to enhance the efficiency and safety of school transportation. Below is an overview of each product:</p>

            <div className={styles.productsGrid}>
                {products.map((product, index) => (
                    <div key={index} className={styles.productCard}>
                        <h3>{React.cloneElement(product.icon, { className: styles.productIcon })} {product.name}</h3>
                        <p>{product.description}</p>
                    </div>
                ))}
            </div>

            <h2 className={styles.sectionTitle}>
                <InfoCircle className={styles.icon} /> How to Access the Products
            </h2>
            <div className={styles.infoBox}>
                <p><strong>Navigating to the Products Page:</strong> To access a product, navigate to the "Products" section from the main dashboard. Each product is displayed in a card format, with the icon and name for easy identification.</p>
                <p><strong>Using a Product:</strong> You will first be prompted to login/signup for a product before accessing it. Click on any product's card. After logging in, you will access the product's detailed functionality. 
                Follow on-screen prompts and instructions to use each product effectively.</p>
            </div>

            <h2 className={styles.sectionTitle}>
                <CheckCircle className={styles.icon} /> Example: Using the Vehicle Odometer Product
            </h2>
            <ol className={styles.stepsList}>
                <li>Click on the "Vehicle Odometer" card.</li>
                <li>View the real-time data displayed, which includes the total distance traveled by each vehicle in your fleet.</li>
                <li>Use this data to schedule vehicle maintenance or analyze vehicle usage patterns.</li>
            </ol>

            <h2 className={styles.sectionTitle}>
                <Box className={styles.icon} /> Best Practices
            </h2>
            <ul className={styles.bestPractices}>
                <li><strong>Regular Monitoring:</strong> Use the Vehicle Live Video and Communications products daily to ensure all activities are being monitored and communication channels are open.</li>
                <li><strong>Data Review:</strong> Periodically review data from the Vehicle Odometer and Driver Details products to ensure that vehicles are well-maintained and drivers are performing as expected.</li>
                <li><strong>Optimize Routinely:</strong> Frequently use the Pickup/Drop-off Optimization product to adjust schedules and routes based on the latest data, ensuring efficiency and reducing delays.</li>
            </ul>

            <h2 className={styles.sectionTitle}>
                <ExclamationTriangle className={styles.icon} /> Troubleshooting
            </h2>
            <div className={styles.troubleshooting}>
                <p><strong>If a product is not responding:</strong> Ensure you have a stable internet connection. If the issue persists, contact technical support.</p>
                <p><strong>For data discrepancies:</strong> Verify the data by cross-referencing with other records, and report any anomalies to your administrator.</p>
            </div>

            <h2 className={styles.sectionTitle}>
                <CheckCircle className={styles.icon} /> Conclusion
            </h2>
            <p>These products are designed to help you manage school transportation effectively, ensuring safety, efficiency, and clear communication. 
                Make sure to use them regularly and follow best practices for the best results.</p>
            <p>For further assistance, refer to the individual product documentation or contact support.</p>
        </div>
    );
}

export default Guide;