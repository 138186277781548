import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import fetchDataFromDynamoDB from './DynamoDBService';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';
import { ResponsiveContainer, BarChart, Legend, Tooltip, XAxis, YAxis, CartesianGrid, Bar } from 'recharts';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);

function VehicleDetailPage() {
    const { deviceId } = useParams();
    const navigate = useNavigate();
    const [vehicle, setVehicle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Set default date range to current week (Monday to Sunday)
    const [startDate, setStartDate] = useState(dayjs().startOf('isoWeek'));
    const [endDate, setEndDate] = useState(dayjs().endOf('isoWeek'));

    const [filteredData, setFilteredData] = useState([]);
    const [totalDistance, setTotalDistance] = useState(0);

    const [lastUpdated, setLastUpdated] = useState(dayjs().format('HH:mm:ss'));

    const isDesktop = window.matchMedia('(min-width: 768px)').matches;

    useEffect(() => {
        const fetchVehicle = async () => {
            try {
                const vehicles = await fetchDataFromDynamoDB();
                const selectedVehicle = vehicles.find(v => v.deviceId === deviceId);
                if (selectedVehicle) {
                    setVehicle(selectedVehicle);
                } else {
                    console.warn('Vehicle not found for deviceId:', deviceId);
                }
            } catch (err) {
                console.error('Error fetching vehicle:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchVehicle();
        setLastUpdated(dayjs().format('HH:mm:ss'));
    }, [deviceId]);
            // the most important section of code.
    useEffect(() => {
        if (vehicle) {
            const data = vehicle.dailyDistances || [];
            const filteredData = [];

            let currentDate = dayjs(startDate);
            while (currentDate.isSameOrBefore(endDate, 'day')) {
                const dateString = currentDate.format('DD-MM-YYYY');
                
                const dailyEntry = data.find(entry => entry.date === dateString);
                
                let dailyDistance = 0;
                let lastValidTime = '_ _:_ _';

                if (dailyEntry) {
                    let maxDistance = 0;
                    dailyEntry.times.forEach(timeEntry => {
                        const timeValue = dayjs(`${dateString} ${timeEntry.time}`, 'DD-MM-YYYY HH:mm');
                        if (timeValue.isSameOrAfter(startDate) && timeValue.isSameOrBefore(endDate)) {
                            dailyDistance += Number(timeEntry.distance);
                            // re-write dailyDistance so that I don't alter much part of the code
                            maxDistance = Math.max(maxDistance, Number(timeEntry.distance));
                            dailyDistance = maxDistance
                            lastValidTime = timeEntry.time;
                        }
                    });
                }

                filteredData.push({
                    date: dateString,
                    day: currentDate.format('ddd'),
                    distance: dailyDistance,
                    mostRecentTime: lastValidTime,
                    dateWithDay: `${currentDate.format('ddd')}, ${dateString}`
                });

                currentDate = currentDate.add(1, 'day');
            }

            const total = filteredData.reduce((sum, entry) => sum + entry.distance, 0);
            setFilteredData(filteredData);
            setTotalDistance(total);
        }
    }, [startDate, endDate, vehicle]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress sx={{ color: '#FF9900' }} />
            </Box>
        );
    }
    if (error) return <p>Error loading vehicle details: {error}</p>;
    if (!vehicle) return <p>Vehicle not found.</p>;


return (
    <div 
        className="container mt-4" 
        style={{ 
            maxWidth: '90%',  
            margin: '0 auto', 
            padding: '10px'   
        }}
    >
        <Card sx={{ mb: 2, borderRadius: 2, boxShadow: 3, backgroundColor: '#F7F7F7' }}>
            <CardContent>
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        mb: 3, 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(10px, 1.5vw, 14px)', 
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                    startIcon={<i className="bi bi-arrow-left-circle"></i>}
                >
                    Back
                </Button>
                <Typography 
                    variant="h6" 
                    sx={{ 
                        color: '#FF9900', 
                        mb: 3, 
                        fontSize: 'clamp(18px, 3vw, 24px)', 
                        fontWeight: 'bold',
                    }}
                >
                    {vehicle.vehicleRegNumber}
                </Typography>
                <Typography 
                    variant="body2" 
                    sx={{ 
                        color: '#232F3E', 
                        mb: 2, 
                        fontSize: 'clamp(12px, 2vw, 14px)' 
                    }}
                >
                    Last Updated: {lastUpdated}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        <Grid item xs={12} md={6}>
                            <DateTimePicker
                                label="Start Date and Time"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                slotProps={(params) => 
                                    <TextField 
                                        {...params} 
                                        fullWidth 
                                        sx={{ 
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#F7F7F7',
                                                borderRadius: '8px',
                                                padding: '12px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#FF9900',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#232F3E',
                                                fontSize: 'clamp(12px, 2vw, 14px)',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: '#232F3E',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#FF9900',
                                            },
                                        }} 
                                    />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePicker
                                label="End Date and Time"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                slotProps={(params) => 
                                    <TextField 
                                        {...params} 
                                        fullWidth 
                                        sx={{ 
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#F7F7F7',
                                                borderRadius: '8px',
                                                padding: '12px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#FF9900',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#232F3E',
                                                fontSize: 'clamp(12px, 2vw, 14px)',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: '#232F3E',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#FF9900',
                                            },
                                        }} 
                                    />}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                <Typography 
                    variant="h5" 
                    sx={{ 
                        color: '#FF9900', 
                        mb: 3, 
                        fontSize: 'clamp(14px, 2.5vw, 18px)', 
                        fontWeight: 'bold',
                    }}
                >
                    Total Distance: {totalDistance.toFixed(1)} km
                </Typography>
            </CardContent>
        </Card>

        <Grid container spacing={2} sx={{ mt: 3 }}>
            {filteredData.length > 0 ? (
                filteredData.map((entry, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Card sx={{ borderRadius: 2, boxShadow: 3, backgroundColor: '#F7F7F7' }}>
                            <CardContent>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Avatar 
                                            sx={{ 
                                                bgcolor: '#1D2731', 
                                                width: 35, 
                                                height: 35, 
                                                mr: 2,
                                                fontSize: 'clamp(12px, 2vw, 14px)' 
                                            }}
                                        >
                                            {entry.day}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={{ color: '#000000', fontSize: 'clamp(12px, 2vw, 14px)' }}>
                                            Date: {entry.date}
                                        </Typography>
                                        <Typography sx={{ color: '#000000', fontSize: 'clamp(12px, 2vw, 14px)' }}>
                                            Distance: {entry.distance} km as of {entry.mostRecentTime || 'No data'} hrs
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                ))
            ) : (
                <Grid item xs={12}>
                    <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography sx={{ color: '#232F3E', fontSize: 'clamp(12px, 2vw, 14px)' }}>
                                No data available for the selected date range.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}

            {isDesktop ? (
                <Grid item xs={12}>
                    <Box sx={{ height: 400 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={filteredData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="dateWithDay" stroke="#232F3E" />
                                <YAxis stroke="#232F3E" />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="distance" fill="#FF9900" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Box sx={{ 
                        p: 2, 
                        backgroundColor: '#FFF3CD', 
                        border: '1px solid #FFEEBA', 
                        borderRadius: 1, 
                        mb: 3,
                        textAlign: 'center',
                        fontSize: 'clamp(12px, 2vw, 14px)' 
                    }}>
                        <Typography sx={{ color: '#856404', fontSize: 'inherit' }}>
                            The graph is available only on larger screens. Please view this page on a tablet, laptop or desktop.
                        </Typography>
                    </Box>
                </Grid>
            )}
        </Grid>
    </div>
);
}

export default VehicleDetailPage;